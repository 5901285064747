.Connect {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Connect-Button {
  border: none;
  font-size: 2rem;
  padding: 2rem;
  border-radius: 5px;
  background-color: cyan;
  color: white;
}
