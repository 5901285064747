.Gamebrowser {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Gamebrowser-Content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Divider {
  border-top: 1px solid black;
  width: 80%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.Login-Form {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  background-color: cyan;
  padding: 2rem;
  border-radius: 5%;
}

.Login-Form-Input {
  height: 1.5rem;
  border: none;
}

.Login-Form-Button {
  height: 1.5rem;
  border: none;
}
