.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.App-Footer {
  padding: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
