.Table {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border: 2px solid grey;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 2px 2px 4px;
}
