.Player {
  padding: 0.5rem;
  border-radius: 2px;
}

.Player.Playing {
  background-color: cyan;
}

.Player.Trick-Winner {
  background-color: gold;
}

.Player.Hand-Winner {
  background-color: gold;
}
