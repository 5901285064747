.Card {
  border: 1px solid black;
  border-radius: 3px;
  height: 5rem;
  width: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: white;
  font-weight: bold;
  font-size: 2rem;
  color: black;
  align-items: center;
  cursor: default;
  transform: translateY(0px);
  transition: transform 0.25s;
}

.Card-Count {
  display: none;
}

@media only screen and (max-width: 700px) {
  .Card.Index1 {
    display: none;
  }
  .Card.Index2 {
    display: none;
  }
  .Card.Index3 {
    display: none;
  }
  .Card.Index4 {
    display: none;
  }
  .Card.Index5 {
    display: none;
  }
  .Card-Count {
    display: flex;
  }
}

.Card.Clickable:hover {
  transform: translateY(-10px);
}

.Card.Clickable {
  :hover {
    cursor: pointer;
  }
}

.Card.Trump {
  text-shadow: #fc0 1px 0 10px;
}

.Card.Spacer {
  border: 1px solid transparent;
}

.Card.Black {
  color: black;
}

.Card.Red {
  color: red;
}

.Card.Selected {
  background-color: yellow;
}

.Card.Disabled {
  background-color: lightgray;
}
