.App-Header {
  background-color: white;
  display: flex;
  flex-direction: row;
}

.App-Header-Name {
  color: cyan;
  font-size: 2rem;
  font-weight: bold;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.App-Header-Content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.App-Header-Server {
  padding: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.App-Header-User {
  padding: 0.5rem;
  margin-left: 1rem;
}

.App-Header-Game {
  padding: 0.5rem;
}

.Header-Alt {
  display: none;
}

@media only screen and (max-width: 700px) {
  .Header-Alt {
    display: flex;
  }
  .App-Header-Name {
    display: none;
  }
  .App-Header-Content {
    display: none;
  }
  .Score-Bottom {
    display: none;
  }
  .Tricks-Bottom {
    display: none;
  }
}
